import {Injectable} from '@angular/core';
import {ErrorHandlerService} from "@makeo-packages/mkongtools";

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandlerService {

    private filters: RegExp[] = [
        /Virtual list.*/,
        /Converting circular structure to JSON.*/,
        /Cannot read properties of.*/,
        /Cannot find control with name.*/,
        /.*already .*destroyed.*/,
        /NG.*/,
        /.*is not an object.*/,
        /.*is not a function.*/,
    ];

    handleError(error: any) {
        const msg: string = error.error?.message || error.statusText || error.message;
        if (msg && this.filters.some(reg => msg.match(reg))) {
            console.error(error)
        } else {
            super.handleError(error);
        }
    }
}
